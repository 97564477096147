import React from "react";
import { useEffect, useState } from 'react';
import moment from 'moment';
import CircularProgressBar from "../circularProgress";

function Timer() {
  const [day, setday] = useState(0);
  const [hour, sethour] = useState(0);
  const [minute, setminute] = useState(0);
  const [second, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const end = '07/31/2022';
      const start = '06/30/2022';
      const startDiff = moment(start).diff(moment(), 'seconds');
      if (startDiff > 0) {
        const days = moment(start).diff(moment(), 'days');
        const hours = moment(start).add(-days, 'days').diff(moment(), 'hours');
        const minute = moment(start).add(-days, 'days').add(-hours, 'hours').diff(moment(), 'minutes');
        const seconds = moment(start)
          .add(-days, 'days')
          .add(-hours, 'hours')
          .add(-minute, 'minutes')
          .diff(moment(), 'seconds');
        setday(days <= 0 ? '00' : days <= 9 ? `0${days}`.slice(-2) : days);
        sethour(hours <= 0 ? '00' : hours <= 9 ? `0${hours}`.slice(-2) : hours);
        setminute(minute <= 0 ? '00' : minute <= 9 ? `0${minute}`.slice(-2) : minute);
        setSeconds(seconds <= 0 ? '00' : seconds <= 9 ? `0${seconds}`.slice(-2) : seconds);
      } else {
        const days = moment(end).diff(moment(), 'days');
        const hours = moment(end).add(-days, 'days').diff(moment(), 'hours');
        const minute = moment(end).add(-days, 'days').add(-hours, 'hours').diff(moment(), 'minutes');
        const seconds = moment(end)
          .add(-days, 'days')
          .add(-hours, 'hours')
          .add(-minute, 'minutes')
          .diff(moment(), 'seconds');
        setday(days <= 0 ? '00' : days <= 9 ? `0${days}`.slice(-2) : days);
        sethour(hours <= 0 ? '00' : hours <= 9 ? `0${hours}`.slice(-2) : hours);
        setminute(minute <= 0 ? '00' : minute <= 9 ? `0${minute}`.slice(-2) : minute);
        setSeconds(seconds <= 0 ? '00' : seconds <= 9 ? `0${seconds}`.slice(-2) : seconds);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [second, minute, hour, day]);
    return ( 
        <>
        <div className="timer-label">Offer Ends In</div>
        <div className="circular-timer">
        <CircularProgressBar strokeColor='#08104D' sublabel='Days'  percentage={(day * 100) / 31} label={`${day}`}  />
        <CircularProgressBar strokeColor='#08104D' sublabel='Hours' percentage={(hour * 100) / 24} label={`${hour}`} />
        <CircularProgressBar strokeColor='#08104D' sublabel='Minutes' percentage={(minute * 100) / 60} label={`${minute}`} />
        <CircularProgressBar strokeColor='#08104D' sublabel='Sec' percentage={(second * 100) / 60} label={`${second}`} />
        </div>
        </>
     );
}

export default Timer;
